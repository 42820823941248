import React from 'react';
import { Dialog } from '@headlessui/react';

const ReceiptModal = ({ receipt, onClose }) => {
  return (
    <Dialog open={true} onClose={onClose} className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen bg-black bg-opacity-50">
        <div className="bg-[#FFF7E1] max-w-3xl mx-auto p-6 rounded shadow-sm my-6" id="invoice">
        <Dialog.Title className="text-2xl font-semibold text-[#29333d]">Order Receipt</Dialog.Title>

          {/* Client info */}
          <div className="grid grid-cols-2 items-center mt-8">
            <div>
            <p>Order ID: <span className="text-gray-500">{receipt?.orderID}</span></p>
              <p>
              Order Date: <span className="text-gray-500">
               { receipt && new Date(receipt?.createdAt).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric"
              })}{" "}
              {receipt && new Date(receipt?.createdAt).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: true
              })}
            </span>
              </p>
            </div>

          </div>

          <div class="-mx-4 mt-8 flow-root sm:mx-0">
        <table class="min-w-full">
          <colgroup>
            <col class="w-full sm:w-1/2" />
            <col class="sm:w-1/6" />
            <col class="sm:w-1/6" />
            <col class="sm:w-1/6" />
          </colgroup>
          <thead class="border-b border-gray-300 text-gray-900">
            <tr>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Items</th>
              <th scope="col" class="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell">Quantity</th>
              <th scope="col" class="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell">Price</th>
              <th scope="col" class="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">Amount</th>
            </tr>
          </thead>
          <tbody>
      {receipt?.items.map((item, index) => (
        <tr key={index} className="border-b border-gray-200">
          {/* Item Name */}
          <td className="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
            <div className="font-medium text-gray-900">{item.name}</div>
            <div className="mt-1 truncate text-gray-500">{item.answers[0]?.map((answerItem, i) => (
                <div key={i}>
                  <strong>{answerItem.question}:</strong> {answerItem.answers.join(", ")}
                </div>
              ))}</div>
          </td>

          {/* Quantity */}
          <td className="hidden px-3 py-5 text-right text-sm text-gray-500 sm:table-cell">
            {item.total_quantity}
          </td>

          {/* Price */}
          <td className="hidden px-3 py-5 text-right text-sm text-gray-500 sm:table-cell">
            ${item.price}
          </td>

          {/* Total Amount */}
          <td className="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
            ${item.total_price}
          </td>
        </tr>
      ))}
    </tbody>
          <tfoot>
            <tr>
              <th scope="row" colspan="3" class="hidden pl-4 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0">Subtotal</th>
              <th scope="row" class="pl-6 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden">Subtotal</th>
              <td class="pl-3 pr-6 pt-6 text-right text-sm text-gray-500 sm:pr-0">{receipt?.subtotal}</td>
            </tr>
            <tr>
              <th scope="row" colspan="3" class="hidden pl-4 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0">Tax (14%)</th>
              <th scope="row" class="pl-6 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden">Tax</th>
              <td class="pl-3 pr-6 pt-4 text-right text-sm text-gray-500 sm:pr-0">{receipt?.orderTaxes}</td>
            </tr>
            <tr>
              <th scope="row" colspan="3" class="hidden pl-4 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0">Service (12%)</th>
              <th scope="row" class="pl-6 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden">Service</th>
              <td class="pl-3 pr-6 pt-4 text-right text-sm text-gray-500 sm:pr-0">{receipt?.orderService}</td>
            </tr>
            <tr>
              <th scope="row" colspan="3" class="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0">Total</th>
              <th scope="row" class="pl-6 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">Total</th>
              <td class="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">{receipt?.orderTotal}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="mt-6 flex justify-end">
                        <button
                            className="px-4 py-1 bg-[#F0532F] text-white font-semibold rounded-md mr-2"
                            onClick={onClose}
                        >
                            Close
                        </button>
      </div>
      <div class="border-t-2 pt-4 text-xs text-[#FFF7E1] text-center mt-16">
        ----------------------------------------------------------------------------------------------------------------------
      </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ReceiptModal;
